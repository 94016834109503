import React from 'react';
import SwiperCore, { Autoplay, EffectFade, Navigation } from 'swiper';
import { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import HomepageSliderCard from '../../components/SliderElements/HomepageSliderCard';

const HomepageSlider = () => {
    var SliderCardData = []

    if (window.innerWidth > 768) {
        // SliderCardData = [
        //     {
        //         id: '1',
        //         bgImg: '/images/bg/slider1.webp',
        //     },
        //     {
        //         id: '2',
        //         bgImg: '/images/bg/slider2.webp',
        //     },
        //     {
        //         id: '3',
        //         bgImg: '/images/bg/slider3.webp',
        //     },
        //     {
        //         id: '4',
        //         bgImg: '/images/bg/slider4.webp',
        //     },
        //     {
        //         id: '5',
        //         bgImg: '/images/bg/slider5.webp',
        //     }
        // ];

        SliderCardData = [
            {
                id: '1',
                bgImg: '/images/bg/2024/unbound-2024-slider.webp',
            }
        ];
    }
    else {
        // SliderCardData = [
        //     {
        //         id: '1',
        //         bgImg: '/images/bg/slider1-mobile.webp',
        //     },
        //     {
        //         id: '2',
        //         bgImg: '/images/bg/slider2-mobile.webp',
        //     },
        //     {
        //         id: '3',
        //         bgImg: '/images/bg/slider3-mobile.webp',
        //     },
        //     {
        //         id: '4',
        //         bgImg: '/images/bg/slider4-mobile.webp',
        //     },
        //     {
        //         id: '5',
        //         bgImg: '/images/bg/slider5-mobile.webp',
        //     }
        // ];

        SliderCardData = [
            {
                id: '1',
                bgImg: '/images/bg/2024/unbound-2024-slider-mobile.webp',
            }
        ];
    }
    SwiperCore.use([Autoplay]);

    return (
        <div className="theme_slider bg-black">
            <div className="container">
                <div className="swiper swiper_theme_slider" data-swiper-autoplay="500">
                    <Swiper
                        modules={[Pagination, EffectFade, Navigation]}
                        effect="slide"
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 5500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                    >
                        {
                            SliderCardData.map(data =>
                                <SwiperSlide>
                                    <HomepageSliderCard
                                        key={data.id}
                                        data={data}
                                    />
                                </SwiperSlide>)
                        }

                    </Swiper>

                    {/* <!-- Add Buttons --> */}
                    <div className="swiper-navigation">
                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                    </div>
                </div>

            </div>
        </div>
        
    );
};

export default HomepageSlider;