import React, { useState, useEffect, useRef } from 'react';

const UnboundSliderCard = (props) => {

  useEffect(() => {
    function makeTimer() {
      const endTime = new Date("18 October 2024 17:00");
      const endTimeInSeconds = Date.parse(endTime) / 1000;

      const now = new Date();
      const nowInSeconds = Date.parse(now) / 1000;

      let timeLeft = endTimeInSeconds - nowInSeconds;

      let days = Math.floor(timeLeft / 86400);
      let hours = Math.floor((timeLeft - days * 86400) / 3600);
      let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
      let seconds = Math.floor(timeLeft - days * 86400 - hours * 3600 - minutes * 60);

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      // Update the countdown values
      document.getElementById("days").textContent = days;
      document.getElementById("hours").textContent = hours;
      document.getElementById("minutes").textContent = minutes;
      document.getElementById("seconds").textContent = seconds;
    }

    // Call makeTimer initially
    makeTimer();

    // Set an interval to call makeTimer every second
    const interval = setInterval(makeTimer, 1000);

    setTimeout(function () {
      document.getElementById('countdown').classList.add('show');
    }, 250); // 1000 milliseconds = 1 second

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  // const modalRef = useRef(null);
  const isMobile = window.innerWidth <= 500;

  // const handleModalOpen = () => {
  //   // Open the modal without specific content
  //   setModalContent(null);
  //   setModalVisible(true);
  // };

  // const handleModalClose = () => {
  //   // Remove the 'status' parameter from the URL
  //   const url = window.location.href;
  //   const newUrl = url.split('?')[0]; // Remove the query string
  //   window.history.pushState({}, '', newUrl);

  //   // Close the modal
  //   setModalVisible(false);
  // };

  // const handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   const newValue = type === 'checkbox' ? checked : value;

  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: newValue,
  //   }));
  // };

  // const handleOverlayClick = (e) => {
  //   // Check if the click event occurred outside the modal content
  //   if (modalRef.current && !modalRef.current.contains(e.target)) {
  //     handleModalClose();
  //   }
  // };

  // const handleDaysChange = (e) => {
  //   const { value, checked } = e.target;

  //   const updatedDays = checked
  //     ? [...formData.days, value]
  //     : formData.days.filter((day) => day !== value);

  //   setFormData({ ...formData, days: updatedDays });
  //   setDaysChecked(updatedDays.length > 0);
  // };

  // const handleWorkshopsChange1 = (e) => {
  //   const { value, checked } = e.target;
  //   const updatedWorkshop1 = checked ? value : '';
  //   setFormData({ ...formData, workshop1: updatedWorkshop1 });
  //   setWorkshopsChecked1(updatedWorkshop1 !== '');
  // };

  // const handleWorkshopsChange2 = (e) => {
  //   const { value, checked } = e.target;
  //   const updatedWorkshop2 = checked ? value : '';
  //   setFormData({ ...formData, workshop2: updatedWorkshop2 });
  //   setWorkshopsChecked2(updatedWorkshop2 !== '');
  // };

  // const generateRandomString = () => {
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   const length = 20;
  //   let randomString = '';
  //   for (let i = 0; i < length; i++) {
  //     const randomIndex = Math.floor(Math.random() * characters.length);
  //     randomString += characters.charAt(randomIndex);
  //   }
  //   return randomString;
  // };

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();

  //   if (formData.days.length === 0 || formData.workshop1.length === 0 || formData.workshop2.length === 0) {
  //     // Display an error message or handle the error as needed
  //     return;
  //   }

  //   try {
  //     const generatedString = generateRandomString();

  //     Cookies.set('userID', generatedString);
  //     const response = await axios.post('/api/unbound/savedata', {
  //       cookieValue: generatedString,
  //       formData: formData
  //     });

  //     // Handle the API response here if needed
  //     console.log('API Response:', response.data);
  //     window.location.href = 'https://epl.ro/q/NGxjam6ElmztbBcPKEcNDrj*4VH2V6U=';

  //   } catch (error) {
  //     console.error('An error occurred while submitting the form:', error);
  //   }
  // };

  return (
    <div className="swiper-slide">
      {/* <div className="slider" style={{
        backgroundImage: `url(${props.bgImg})`,
        paddingBottom: modalVisible ?
          isMobile ?
            modalContent !== null ? `calc(11.625rem + 24.42857vw)` : `calc(74.625rem + 24.42857vw)`
            : `calc(12.625rem + 24.42857vw)`
          : `calc(5.625rem + 15.42857vw)`
      }}> */}

      <div className='slider' style={{ backgroundImage: `url(${props.bgImg})` }}>
        <div className="container">
          <div className="slide_content">
            <div className="slide_content_wrapper mb-0 h-auto">
              <div className="slide_content_inner">
                <h4 className="title-unbound-page">
                  <div className="text-uppercase">{props.title}</div>
                </h4>
                <h4>
                  <div className="date-unbound-page">18-19 Octombrie</div>
                </h4>
                {/* Modal open */}
                {/* <div className="details_link">
                  <button className="unbound-button" onClick={handleModalOpen}>
                    <span className="link_text">Înscriere</span>
                  </button>
                </div>
                <div className="under-construction-page">
                  <div className="under-construction-content">
                    <div id="countdown">
                      <ul>
                        <li>
                          <span id="days"></span>days
                        </li>
                        <li>
                          <span id="hours"></span>Hours
                        </li>
                        <li>
                          <span id="minutes"></span>Minutes
                        </li>
                        <li>
                          <span id="seconds"></span>Seconds
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="details_link">
                  <button className="unbound-button" onClick={() => window.open('https://tickets.voxdei.ro/locale/set?locale=ro&next=/vox-dei/unbound2024/', '_blank')}>
                    <span className="link_text">Înscriere</span>
                  </button>
                </div>
                <div className="under-construction-page">
                  <div className="under-construction-content">
                    <div id="countdown">
                      <ul>
                        <li>
                          <span id="days"></span>days
                        </li>
                        <li>
                          <span id="hours"></span>Hours
                        </li>
                        <li>
                          <span id="minutes"></span>Minutes
                        </li>
                        <li>
                          <span id="seconds"></span>Seconds
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="date-unbound-page">
                <span style={{ fontSize: `18px`}}>Înscrierea online s-a încheiat! Te mai poți înscriere direct la conferință achitând taxa de înscriere în numerar.</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnboundSliderCard;
